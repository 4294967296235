import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { InitializeAppArgs } from '@react-firebase/auth/dist/types';

const config: InitializeAppArgs = {
  apiKey: "AIzaSyCPJ3jfhXzSl1uGwK6F33n4bhQydajl_3E",
  authDomain: "nt-fishing-mate-ktran.firebaseapp.com",
  projectId: "nt-fishing-mate-ktran",
  databaseURL: '',
  storageBucket: "nt-fishing-mate-ktran.appspot.com",
  messagingSenderId: "579984541457",
  appId: "1:579984541457:web:ac7259d2bd59b74bf76eb0",
  firebase
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAuthProvider {...config}>
      <App />
    </FirebaseAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
