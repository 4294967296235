import React, { Suspense, lazy } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { FirebaseAuthConsumer } from '@react-firebase/auth';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthEmission } from '@react-firebase/auth/dist/types';
import { ToastContainer, toast } from 'react-toastify';
import { StoreContext } from './context';
import LoadingPlaceholder from './component/loading-placeholder';

const MainPage: React.LazyExoticComponent<React.FC> = lazy(() => import('./pages/main'));
const LoginPage: React.LazyExoticComponent<React.FC> = lazy(() => import('./pages/login'));

const App: React.FC = () => (
  <FirebaseAuthConsumer>
    {(children: AuthEmission) => {
      if (_isEmpty(children.firebase) === true) {
        return <div className='flex h-screen w-full'>
          <LoadingPlaceholder fullPage />
        </div>
      }

      return (
        (
          <StoreContext.Provider value={{
            firebaseEmis: children,
            globalToast: toast
          }}>
            <Router>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route exact path="/login" component={LoginPage} />
                  <Route path="/" component={MainPage} />
                </Switch>
              </Suspense>
            </Router>
            <ToastContainer />
          </StoreContext.Provider>
        )
      )
    }}
  </FirebaseAuthConsumer>
);

export default App;
